import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import { TimelineHelpers } from 'components/template-designer/helpers/timeline.helpers';
import cloneDeep from 'helpers/cloneDeep';
import { TIMELINE_SIDE_SNAP_THRESHOLD_PX } from 'components/template-designer/constants';
import { useTimelineDragContext } from '../components/tracks/context/timline-drag-context';

interface UseTimelineDragOptions<Dependencies = unknown[]> {
    stamp: number;
    timelineWidth: number;
    onDragEnd: (newStamp: number, dependencies: Dependencies) => void;
    onDrag: (newStamp: number, dependencies: Dependencies) => void;
    min?: number;
    max?: number;
    snapPoints?: number[];
    dependencies?: Dependencies;
}

const useTimelineDrag = <Dependencies = unknown[]>({
    stamp,
    timelineWidth,
    onDragEnd,
    onDrag,
    min,
    max,
    snapPoints,
    dependencies = [] as Dependencies
}: UseTimelineDragOptions<Dependencies>): { dragRef: RefObject<HTMLDivElement>; isDragging: boolean } => {
    const { dragHandleObject, setDragHandleObject } = useTimelineDragContext();

    const [isDragging, setIsDragging] = useState(false);

    const dragRef = useRef<HTMLDivElement>(null);
    const id = useMemo(() => uuidv4(), []);
    const prevDragRef = useRef<HTMLDivElement | null>(null);
    const newStampRef = useRef<number>(stamp);
    const originalStampRef = useRef<number>(stamp);
    const originalXRef = useRef<number>(0);
    const minRef = useRef<number | undefined>(min);
    const maxRef = useRef<number | undefined>(max);
    const snapPointsRef = useRef<number[] | undefined>(snapPoints);
    const timelineWidthRef = useRef<number | undefined>(timelineWidth);
    const dependenciesRef = useRef<Dependencies>(dependencies);

    /**
     * Update the refs with the new values.
     */
    useEffect(() => {
        if (min !== minRef.current) minRef.current = min;
        if (max !== maxRef.current) maxRef.current = max;
        if (!isEqual(snapPoints, snapPointsRef.current)) snapPointsRef.current = snapPoints;
        if (timelineWidth !== timelineWidthRef.current) timelineWidthRef.current = timelineWidth;
        if (!isEqual(dependencies, dependenciesRef.current)) dependenciesRef.current = dependencies;
    }, [min, max, timelineWidth, snapPoints, dependencies]);

    /**
     * Update the original stamp when the stamp changes to be used in the event handlers.
     */
    useEffect(() => {
        if (isDragging) return;
        if (originalStampRef.current !== stamp) {
            originalStampRef.current = stamp;
        }
    }, [stamp, isDragging]);

    /**
     * Handle the mouse move event, update the state and ref with the new stamp.
     * @param event - The mouse event.
     */
    const handleMouseMove = (event: MouseEvent) => {
        event.stopPropagation();
        setIsDragging(true);
        const pixelsMoved = event.clientX - originalXRef.current;
        const stampMoved = TimelineHelpers.pixelsToStamp(pixelsMoved, timelineWidthRef.current || 0);
        let calculatedStamp = originalStampRef.current + stampMoved;

        if (minRef.current !== undefined && minRef.current !== null && calculatedStamp < minRef.current) calculatedStamp = minRef.current;
        if (maxRef.current !== undefined && maxRef.current !== null && calculatedStamp > maxRef.current) calculatedStamp = maxRef.current;

        if (snapPointsRef.current?.length) {
            for (const snapPoint of snapPointsRef.current) {
                if (Math.abs(calculatedStamp - snapPoint) < TimelineHelpers.pixelsToStamp(TIMELINE_SIDE_SNAP_THRESHOLD_PX, timelineWidthRef.current || 0)) {
                    calculatedStamp = snapPoint;
                    break;
                }
            }
        }

        const distance = calculatedStamp - originalStampRef.current;
        onDrag?.(distance, dependenciesRef.current);
        newStampRef.current = calculatedStamp;
    };

    /**
     * Handle the mouse up event,
     * remove the event listeners and update the state with the new stamp
     * Also call the update function with the new stamp
     */
    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        setIsDragging(false);

        const distance = newStampRef.current - originalStampRef.current;
        originalStampRef.current = newStampRef.current;

        if (distance === 0) return;
        // There is a timeout on onDragEnd of 100 ms because the setState that happens a lot in onDrag can take some time.
        // If the onDragEnd function is fired before that async setState is done the last onDrag will update the wrong time (on that is already update by redux as well)
        setTimeout(() => onDragEnd(distance, dependenciesRef.current), 100);
    };

    /**
     * Handle the mouse down event, set the original x position and add the event listeners.
     * @param event - The mouse event.
     */
    const handleMouseDown = (event: MouseEvent) => {
        event.stopPropagation();
        newStampRef.current = originalStampRef.current;
        originalXRef.current = event.clientX;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    /**
     * Save the event handle function in the dragHandleObject with the uniqueId and store the uniqueId in the element
     */
    useEffect(() => {
        if (dragRef.current && dragRef.current !== prevDragRef.current) {
            prevDragRef.current = dragRef.current;

            dragRef.current.setAttribute('data-unique-id', id);

            // Register the logic in a global map or context.
            setDragHandleObject((dragHandleObject) => {
                const newDragHandleObject = cloneDeep(dragHandleObject);
                set(newDragHandleObject, id, handleMouseDown);
                return newDragHandleObject;
            });
        }
    }, [dragRef.current]);

    useEffect(() => {
        return () => {
            if (typeof dragHandleObject === 'object' && dragHandleObject) {
                setDragHandleObject((dragHandleObject) => {
                    const newDragHandleObject = cloneDeep(dragHandleObject);
                    delete newDragHandleObject[id];
                    return newDragHandleObject;
                });
            }
        };
    }, []);

    return { dragRef, isDragging };
};

export { useTimelineDrag };
