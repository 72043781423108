import React, { createContext, useContext, useState } from 'react';

interface DragContextValue {
    dragHandleObject: unknown;
    setDragHandleObject: React.Dispatch<
        React.SetStateAction<{
            [key: string]: unknown;
        }>
    >;
}
const TimelineDragContext = createContext({
    dragHandleObject: {},
    setDragHandleObject: () => {
        return false;
    }
} as DragContextValue);

interface Props {
    children: React.ReactNode;
}

/**
 * This is the context provider for all dragging in the timeline.
 * This context stores the dragHandleObject which stores the eventHandleFunctions for each draggable element under their uniqueId.
 * If an element is clicked that has a data-unique-id it will call the eventHandleFunction that is stored in the dragHandleObject with that uniqueId.
 * In this eventHandleFunction event handlers are added to that specific element for mouse move and mouse up.
 * We do this to delegate events; Meaning not too much event listeners are active at one time.
 */
export const TimelineDragContextProvider = ({ children }: Props) => {
    const [dragHandleObject, setDragHandleObject] = useState<{ [key: string]: any }>({});

    const value: DragContextValue = {
        dragHandleObject,
        setDragHandleObject
    };

    /**
     * Return the closest element in the dom that has a data-unique-id attribute.
     * @param {HTMLElement} element - The starting element.
     * @returns {HTMLElement|null} - The element itself, found parent element or null if not found.
     */
    const getUniqueId = (element: EventTarget) => {
        let target: HTMLElement | null = element as HTMLElement;

        if (target.hasAttribute('data-unique-id')) return target.getAttribute('data-unique-id');

        while (target) {
            const uniqueId = target.getAttribute('data-unique-id');
            if (uniqueId) return uniqueId;
            target = target.parentElement;
        }

        return null;
    };

    /**
     * Checks if the clicked element has a uniqueId and calls the eventHandleFunction that is stored in the dragHandleObject with that uniqueId.
     * @param e MouseEvent
     */
    const handleMouseDown = (e: React.MouseEvent) => {
        const target = e.target;
        const uniqueId = getUniqueId(target);
        if (!uniqueId) return;

        const eventHandleFunction = dragHandleObject[uniqueId];

        if (eventHandleFunction) {
            eventHandleFunction(e);
        }
    };

    if (!children) return null;

    return (
        <TimelineDragContext.Provider value={value as DragContextValue}>
            {React.Children.map(children, (child) =>
                React.isValidElement(child) ? React.cloneElement(child as React.ReactElement<any>, { onMouseDown: handleMouseDown }) : child
            )}
        </TimelineDragContext.Provider>
    );
};

export const useTimelineDragContext = () => useContext(TimelineDragContext);
