import set from 'lodash/set';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import Template, { State, View } from 'components/template-designer/types/template.type';
import defaultLayoutGrid from 'components/template-designer/config/defaultLayoutGrid';
import { LayoutGridOptions } from 'components/template-designer/types/formatProperties.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import TemplateDesignerStore from 'components/template-designer/data/template-designer-store';

/**
 * Hook to change the selected layout grid option for the selected format
 */
const useLayoutGrid = <LayoutGridType>(
    model: LayoutGridOptions,
    selectedFormats?: State['selectedFormats']
): {
    changeLayoutGrid: (newLayoutGrid: LayoutGridType, selectedFormats?: State['selectedFormats']) => void;
    resetLayout: () => void;
    layoutGrid: LayoutGridType;
    isOverwritten: boolean;
} => {
    if (!selectedFormats) selectedFormats = getTemplateData<State['selectedFormats']>('state.selectedFormats');
    const frameType = getTemplateData<View['frameType']>('view.frameType');

    // Get guidelines.
    const { formatSpecificLayoutGrid, generalLayoutGrid } = useComponentStore<{
        formatSpecificLayoutGrid: LayoutGridType;
        generalLayoutGrid: LayoutGridType;
    }>('TemplateDesigner', {
        fields: {
            formatSpecificLayoutGrid: `layerProperties.${selectedFormats[0]}.${frameType}.properties.layoutGrid.${model}`,
            generalLayoutGrid: `layerProperties.general.${frameType}.properties.layoutGrid.${model}`
        }
    });
    const layoutGrid: LayoutGridType = formatSpecificLayoutGrid || generalLayoutGrid || defaultLayoutGrid[model];

    const isOverwritten = formatSpecificLayoutGrid && Object.keys(formatSpecificLayoutGrid).length > 0 && selectedFormats[0] !== 'general';

    /**
     * Changes the layout grid of the selected format
     * @param newLayoutGrid The new layout grid to save
     */
    const changeLayoutGrid = (newLayoutGrid: LayoutGridType) => {
        const formats = getTemplateData<Template['formats']>('formats');
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        if (!selectedFormats) selectedFormats = getTemplateData<State['selectedFormats']>('state.selectedFormats');

        // Update style of general guidelines.
        if (selectedFormats.length === formats.length || selectedFormats[0] === 'general') {
            return TemplateDesignerStore.save([`layerProperties.general.${frameType}.properties.layoutGrid.${model}`, newLayoutGrid]);
        }

        // Update style of active format.
        if (selectedFormats[0] !== 'general') {
            // Update format styling of multi selection.

            const changes: [string, unknown][] = [];
            selectedFormats.forEach((format) => {
                let formatProperties = getTemplateData(`layerProperties.${format}.${frameType}.properties`) || {};

                // because of backend constraints the properties object can be an array if this is the case replace it with an object with guidelines in it
                if (Array.isArray(formatProperties))
                    formatProperties = {
                        layoutGrid: {
                            [model]: newLayoutGrid
                        }
                    };
                else set(formatProperties, `layoutGrid.${model}`, newLayoutGrid);
                changes.push([`layerProperties.${format}.${frameType}.properties`, formatProperties]);
            });
            TemplateDesignerStore.save(changes);
        }
    };

    /**
     * Removes all guidelines form format to reset to general guidelines
     */
    const resetLayout = (): void => {
        if (!selectedFormats) selectedFormats = getTemplateData<State['selectedFormats']>('state.selectedFormats');
        const changes: [string, unknown][] = [];
        selectedFormats.forEach((format) => {
            changes.push([`layerProperties.${format}.${frameType}.properties.layoutGrid.${model}`, null]);
        });

        TemplateDesignerStore.save(changes);
    };

    return { changeLayoutGrid, resetLayout, layoutGrid, isOverwritten };
};

export default useLayoutGrid;
