import React, { useState } from 'react';
import { ListItemText, ListItemButton, ListItemIcon } from '@mui/material';
import Checkbox from 'components/ui-components-v2/Checkbox';
import List from 'components/ui-components-v2/List';
import ListItem from 'components/ui-components-v2/ListItem';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import Format from 'components/template-designer/types/format.type';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import { View } from 'components/template-designer/types/template.type';
import TemplateDesignerStore, { MultiModel } from 'components/template-designer/data/template-designer-store';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../../ui-components/dialog';
import '../styles/format-management-copy-dialog.scss';

interface Props {
    onClose: () => void;
    formats: Format[];
    formatToCopy: Format['key'];
    frameType: View['frameType'];
}

/**
 * Format duplicate dialog
 * The component displays a dialog to copy format styling to another format.
 */
const FormatManagementCopyToDialog = ({ onClose, formats, formatToCopy, frameType }: Props) => {
    const [selectedFormats, setSelectedFormats] = useState<Format['key'][]>([]);

    const handleSave = () => {
        const formatPropertiesToCopy = getTemplateData(`layerProperties.${formatToCopy}.${frameType}`);

        const changes: MultiModel = [];

        selectedFormats.forEach((selectedFormat) => {
            changes.push([`layerProperties.${selectedFormat}.${frameType}`, cloneDeep(formatPropertiesToCopy)]);
        });

        TemplateDesignerStore.save(changes);
        onClose();
    };

    const handleFormatSelection = (formatKey: Format['key']) => {
        setSelectedFormats((prevState) => {
            const isAlreadyAdded = prevState.includes(formatKey);

            if (!isAlreadyAdded) {
                return [...prevState, formatKey];
            }

            return prevState.filter((key) => key !== formatKey);
        });
    };

    return (
        <Dialog className="template-designer__copy-format-dialog" open clickAway={onClose} size="sm">
            <DialogTitle onClose={onClose}>{Translation.get('sidebarLeft.formats.dialogs.duplicate.title', 'template-designer')}</DialogTitle>
            <DialogContent className="template-designer__copy-format-dialog__content">
                <List dense disablePadding>
                    {formats?.map((item) => (
                        <ListItem key={item.key} disablePadding disableGutters divider={false} className="template-designer__copy-format-dialog__list-item">
                            <ListItemButton
                                role={undefined}
                                dense
                                className={selectedFormats.includes(item.key) ? 'template-designer__copy-format-dialog__list-item--selected' : ''}
                                onClick={() => handleFormatSelection(item.key)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selectedFormats.includes(item.key)}
                                        id={item.key + '_duplicate'}
                                        disableRipple
                                        size="small"
                                    />
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions className="template-designer__copy-format-dialog__button">
                <Button onClick={handleSave} type="button" variant="contained" color="primary">
                    {Translation.get('sidebarLeft.formats.actions.copyFormatStyling', 'template-designer').toUpperCase()}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FormatManagementCopyToDialog;
