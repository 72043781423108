import Format from 'types/format.type';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import FormatHelpers from 'components/template-designer/helpers/format.helpers';
import {
    ColumnsTypeOptions,
    Guideline,
    LayoutGridUnitOptions,
    LayoutGridOptions,
    SquareGrid,
    Rows,
    Columns,
    LayoutGridDirectionOptions,
    RowTypeOptions,
    LayoutGrid
} from 'components/template-designer/types/formatProperties.type';
import { View } from 'components/template-designer/types/template.type';
import { generateKey } from 'components/template-designer/utils/generateKey';
import defaultLayoutGrid from 'components/template-designer/config/defaultLayoutGrid';

/**
 * Generates guidelines based on the layout grid settings
 * @param layoutGrid Data on which the guidelines are based like count and margin
 * @param format The format of the template
 * @returns Guidelines
 */
const generateRows = (layoutGrid: Rows, format): Guideline[] => {
    const marginPx = (() => {
        if (layoutGrid.margin.unit === LayoutGridUnitOptions.Px) {
            return layoutGrid.margin.value;
        }
        return format.height * (layoutGrid.margin.value / 100);
    })();
    const gutterPx = (() => {
        if (layoutGrid.gutter.unit === LayoutGridUnitOptions.Px) {
            return layoutGrid.gutter.value;
        }
        return format.height * (layoutGrid.gutter.value / 100);
    })();
    const widthPx = (() => {
        if (layoutGrid.height.unit === LayoutGridUnitOptions.Px) {
            return layoutGrid.height.value;
        }
        return format.height * (layoutGrid.height.value / 100);
    })();
    const offsetPx = (() => {
        if (layoutGrid.offset.unit === LayoutGridUnitOptions.Px) {
            return layoutGrid.offset.value;
        }
        return format.height * (layoutGrid.offset.value / 100);
    })();
    const direction: LayoutGridDirectionOptions = (() => {
        if (layoutGrid.type === RowTypeOptions.Stretch || layoutGrid.type === RowTypeOptions.Center) {
            return LayoutGridDirectionOptions.Top;
        }
        return layoutGrid.type as unknown as LayoutGridDirectionOptions;
    })();
    const start = (() => {
        if (layoutGrid.type === RowTypeOptions.Center) {
            const guideWidth = widthPx * layoutGrid.count + gutterPx * (layoutGrid.count - 1);
            return (format.height - guideWidth) / 2;
        }
        if (layoutGrid.type === RowTypeOptions.Stretch) {
            return marginPx;
        }
        return offsetPx;
    })();
    const width = (() => {
        if (layoutGrid.type === RowTypeOptions.Stretch) {
            const totalGutter = gutterPx * (layoutGrid.count - 1);
            const totalMargin = marginPx * 2;
            const totalWidth = format.height - totalMargin - totalGutter;
            return totalWidth / layoutGrid.count;
        }
        return widthPx;
    })();

    const newGuidelines: Guideline[] = [];

    let currentColumn = start;
    for (let i = 0; i < layoutGrid.count; i++) {
        newGuidelines.push({
            id: generateKey(),
            direction: direction,
            value: {
                value: currentColumn,
                unit: LayoutGridUnitOptions.Px
            }
        });
        newGuidelines.push({
            id: generateKey(),
            direction: direction,
            value: {
                value: currentColumn + width,
                unit: LayoutGridUnitOptions.Px
            }
        });
        currentColumn += width + gutterPx;
    }

    return newGuidelines;
};

/**
 * Generates guidelines based on the layout grid settings
 * @param layoutGrid Data on which the guidelines are based like count and margin
 * @param format The format of the template
 * @returns Guidelines
 */
const generateCols = (layoutGrid: Columns, format): Guideline[] => {
    const marginPx = (() => {
        if (layoutGrid.margin.unit === LayoutGridUnitOptions.Px) {
            return layoutGrid.margin.value;
        }
        return format.width * (layoutGrid.margin.value / 100);
    })();
    const gutterPx = (() => {
        if (layoutGrid.gutter.unit === LayoutGridUnitOptions.Px) {
            return layoutGrid.gutter.value;
        }
        return format.width * (layoutGrid.gutter.value / 100);
    })();
    const widthPx = (() => {
        if (layoutGrid.width.unit === LayoutGridUnitOptions.Px) {
            return layoutGrid.width.value;
        }
        return format.width * (layoutGrid.width.value / 100);
    })();
    const offsetPx = (() => {
        if (layoutGrid.offset.unit === LayoutGridUnitOptions.Px) {
            return layoutGrid.offset.value;
        }
        return format.width * (layoutGrid.offset.value / 100);
    })();
    const direction = (() => {
        if (layoutGrid.type === ColumnsTypeOptions.Stretch || layoutGrid.type === ColumnsTypeOptions.Center) {
            return LayoutGridDirectionOptions.Left;
        }
        return layoutGrid.type as unknown as LayoutGridDirectionOptions;
    })();
    const start = (() => {
        if (layoutGrid.type === ColumnsTypeOptions.Center) {
            const guideWidth = widthPx * layoutGrid.count + gutterPx * (layoutGrid.count - 1);
            return (format.width - guideWidth) / 2;
        }
        if (layoutGrid.type === ColumnsTypeOptions.Stretch) {
            return marginPx;
        }
        return offsetPx;
    })();
    const width = (() => {
        if (layoutGrid.type === ColumnsTypeOptions.Stretch) {
            const totalGutter = gutterPx * (layoutGrid.count - 1);
            const totalMargin = marginPx * 2;
            const totalWidth = format.width - totalMargin - totalGutter;
            return totalWidth / layoutGrid.count;
        }
        return widthPx;
    })();

    const newGuidelines: Guideline[] = [];

    let currentColumn = start;
    for (let i = 0; i < layoutGrid.count; i++) {
        newGuidelines.push({
            id: generateKey(),
            direction: direction,
            value: {
                value: currentColumn,
                unit: LayoutGridUnitOptions.Px
            }
        });
        newGuidelines.push({
            id: generateKey(),
            direction: direction,
            value: {
                value: currentColumn + width,
                unit: LayoutGridUnitOptions.Px
            }
        });
        currentColumn += width + gutterPx;
    }

    return newGuidelines;
};

/**
 * Generates guidelines based on the layout grid settings
 * @param layoutGrid Data on which the guidelines are based like count and margin
 * @param format The format of the template
 * @returns Guidelines
 */
const generateSquareGrid = (layoutGrid: SquareGrid): Guideline[] => {
    if (layoutGrid.gridSize.value === 0) return [];

    const newGuidelines: Guideline[] = [];
    if (layoutGrid.gridSize.unit === LayoutGridUnitOptions['%']) {
        for (let i = 0; i < 100; i += layoutGrid.gridSize.value) {
            newGuidelines.push({
                id: generateKey(),
                direction: LayoutGridDirectionOptions.Top,
                value: {
                    value: i,
                    unit: LayoutGridUnitOptions['%']
                }
            });
            newGuidelines.push({
                id: generateKey(),
                direction: LayoutGridDirectionOptions.Left,
                value: {
                    value: i,
                    unit: LayoutGridUnitOptions['%']
                }
            });
        }
    }

    if (layoutGrid.gridSize.unit === LayoutGridUnitOptions.Px) {
        const formatHeight = FormatHelpers.getLargestFormatHeight().height;
        for (let i = 0; i < formatHeight; i += layoutGrid.gridSize.value) {
            newGuidelines.push({
                id: generateKey(),
                direction: LayoutGridDirectionOptions.Top,
                value: {
                    value: i,
                    unit: LayoutGridUnitOptions.Px
                }
            });
        }
        const formatWidth = FormatHelpers.getLargestFormatWidth().width;
        for (let i = 0; i < formatWidth; i += layoutGrid.gridSize.value) {
            newGuidelines.push({
                id: generateKey(),
                direction: LayoutGridDirectionOptions.Left,
                value: {
                    value: i,
                    unit: LayoutGridUnitOptions.Px
                }
            });
        }
    }

    return newGuidelines;
};

/**
 * Generates guidelines
 * @param format The format of the template
 * @returns Guidelines
 */
const generateLines = (format: Format, layoutGridFormat: LayoutGrid | undefined, layoutGridGeneral: LayoutGrid | undefined): Guideline[] => {
    const frameType = getTemplateData<View['frameType']>('view.frameType');
    const layout =
        getTemplateData<LayoutGridOptions>(`layerProperties.${format.key}.${frameType}.properties.layoutGrid.activeLayout`) ||
        getTemplateData<LayoutGridOptions>(`layerProperties.general.${frameType}.properties.layoutGrid.activeLayout`) ||
        defaultLayoutGrid['activeLayout'];

    if (layout === LayoutGridOptions.RowsColumns) {
        const layoutGridRows = layoutGridFormat?.rows || layoutGridGeneral?.rows || defaultLayoutGrid['rows'];
        const layoutGridCols = layoutGridFormat?.columns || layoutGridGeneral?.columns || defaultLayoutGrid['columns'];

        return [...generateRows(layoutGridRows as Rows, format), ...generateCols(layoutGridCols as Columns, format)];
    }

    const layoutGrid = layoutGridFormat?.[layout] || layoutGridGeneral?.[layout] || defaultLayoutGrid[layout];

    if (!layoutGrid || (layout !== LayoutGridOptions.Guidelines && !layoutGrid)) return [];

    if (layout === LayoutGridOptions.Rows) {
        return generateRows(layoutGrid as Rows, format);
    }

    if (layout === LayoutGridOptions.Columns) {
        return generateCols(layoutGrid as Columns, format);
    }

    if (layout === LayoutGridOptions.SquareGrid) {
        return generateSquareGrid(layoutGrid as SquareGrid);
    }

    if (layout === LayoutGridOptions.Guidelines) {
        return layoutGrid as Guideline[];
    }

    return [];
};

export { generateLines };
