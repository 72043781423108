import { useMemo } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';
import unset from 'lodash/unset';
import useComponentStore, { ComponentStoreFields } from 'components/data/ComponentStore/hooks/useComponentStore';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import Layer from 'components/template-designer/types/layer.type';
import LayerProperties from 'components/template-designer/types/layerProperties.type';
import Template, { State, View } from 'components/template-designer/types/template.type';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import { LayerPropertiesHelpers } from 'components/template-designer/helpers/layer-properties.helpers';
import { defaultHoverProperties } from 'components/template-designer/config/layer-properties/default-hover-properties';
import { defaultFormatProperties } from 'components/template-designer/config/layer-properties/default-format-properties';
import { deleteObjectWhenEmpty } from 'components/template-designer/utils/deleteObjectWhenEmpty';
import TemplateDesignerStore, { MultiModel, TemplateDesignerStoreProps } from 'components/template-designer/data/template-designer-store';
import FormatHelpers from 'components/template-designer/helpers/format.helpers';

export type UpdateModel = { model: string; value: unknown | LayerProperties['animations'][keyof LayerProperties['animations']] }[];
export type LayerEditOnChange = (changes: UpdateModel, layerToUpdate?: Layer['key'], storeOptions?: TemplateDesignerStoreProps['options']) => void;
export type LayerEditOverwrites = { [key: string]: boolean };
export type LayerEditFunctionType = (propertyKeys: string[], layer?: Layer['key']) => void;
export type LayerEditResetOverwrites = LayerEditFunctionType;
export type LayerEditApplyOverwriteToAll = LayerEditFunctionType;
export type LayerEditApplyOverwriteToGeneral = LayerEditFunctionType;
export type LayerEditResetHighlightedStyling = LayerEditFunctionType;

interface StoreData {
    selectedLayer: State['selectedLayers'][0];
    frameType: View['frameType'];
    formats: Template['formats'];
    selectedFormats: State['selectedFormats'];
}

interface PropertyData {
    generalStyle: ComponentStoreFields;
    formatStyle: ComponentStoreFields;
    editorStyle: ComponentStoreFields;
}

export interface UseLayerEditReturn<Data> {
    value: Data;
    generalStyle: Data;
    formatStyle: Data;
    onChange: LayerEditOnChange;
    selectedLayer?: State['selectedLayers'][0];
    selectedFormats: State['selectedFormats'];
    overwrites: LayerEditOverwrites;
    resetOverwrites: LayerEditResetOverwrites;
    applyOverwriteToAll: LayerEditApplyOverwriteToAll;
    applyOverwriteToGeneral: LayerEditApplyOverwriteToGeneral;
    resetHighlightedStyling: LayerEditResetHighlightedStyling;
    showResetToGeneral: boolean;
    showApplyToGeneral: boolean;
    showApplyToEveryFormat: boolean;
}

/**
 * Hook to edit a layer.
 * @param propertyKeys - The keys of the layer properties to edit.
 * @param updateKey - The key of the layer properties to update. Either 'properties', 'hover' or 'animations'
 * @param layerKey - The key of the layer to edit. If undefined, the selected layer will be used.
 * @returns Values and functions to edit the layer.
 * @property value - The value of the layer properties.
 * @property generalStyle - The general style of the layer properties.
 * @property formatStyle - The format style of the layer properties.
 * @property onChange - Function to change the layer properties.
 * @property selectedLayer - The selected layer.
 * @property selectedFormats - The selected formats.
 * @property overwrites - The overwrites for the layer properties.
 * @property resetOverwrites - Function to reset the overwrites for the layer properties.
 * @property applyOverwriteToAll - Function to apply the given property keys to all formats.
 * @property applyOverwriteToGeneral - Function to apply the given property keys to the general layer properties.
 * @property showResetToGeneral - Whether to show the reset to general button.
 * @property showApplyToGeneral - Whether to show the apply to general button.
 * @property showApplyToEveryFormat - Whether to show the apply to every format button.
 */
const useLayerEdit = <Data>(propertyKeys: string[], updateKey: keyof LayerProperties = 'properties', layerKey?: Layer['key']): UseLayerEditReturn<Data> => {
    /**
     * Get the data that is needed for the hook.
     * If this data changes, it must re run the hook.
     */
    const { frameType, selectedFormats, selectedLayer, formats } = useComponentStore<StoreData>('TemplateDesigner', {
        fields: {
            formats: 'formats',
            selectedLayer: 'state.selectedLayers[0]',
            frameType: 'view.frameType',
            selectedFormats: 'state.selectedFormats'
        }
    });

    /**
     * If a layer key is given, use that layer.
     * Otherwise use the selected layer.
     */
    const selectedLayerKey = layerKey ?? selectedLayer?.key;

    const selectedFormat = selectedFormats[0];

    const generatedUpdateKey = (() => {
        switch (updateKey) {
            case 'hover':
                // If a layer is selected then use properties.hover, otherwise use hover directly.
                if (selectedLayerKey) return 'hover';
                else return 'properties.hover';
            default:
                return updateKey;
        }
    })();

    /**
     * Generates the models for the given type.
     * @param type - The type of the layer properties. Either 'general' or a format.
     * @returns The generated models for the given type.
     */
    const generateModels = (type: string | null) => {
        if (!type) return {};

        return propertyKeys.reduce((all, key) => {
            let model = `layerProperties.${type}.${frameType}.${generatedUpdateKey}.${key}`;
            if (selectedLayerKey) model = `layerProperties.${type}.${frameType}.${selectedLayerKey}.${generatedUpdateKey}.${key}`;

            const keyParts = key.split('.');
            let modelObject = all;

            for (let i = 0; i < keyParts.length - 1; i++) {
                const keyPart = keyParts[i];
                modelObject[keyPart] = modelObject[keyPart] || {};
                modelObject = modelObject[keyPart] as ComponentStoreFields;
            }

            modelObject[keyParts[keyParts.length - 1]] = model;
            return all;
        }, {} as ComponentStoreFields);
    };

    const fields = {
        generalStyle: generateModels('general'),
        formatStyle: {}
    };

    if (selectedFormat !== 'general') {
        fields.formatStyle = generateModels(selectedFormat);
    }

    /**
     * Get layer properties from general and format.
     */
    const { generalStyle, formatStyle, editorStyle } = useComponentStore<PropertyData>('TemplateDesigner', {
        fields
    });

    /**
     * Changes the layer properties with the given changes.
     * @param changes - The changes to be made.
     * @param layer - The layer to be changed. If undefined, the selected layer will be used. If non is selected, the format properties will be changed.
     */
    const onChange = (changes: UpdateModel, layerToUpdate?: Layer['key'], storeOptions?: TemplateDesignerStoreProps['options']) => {
        // Check which layer to update.
        const selectedLayerKey = layerToUpdate ?? layerKey ?? selectedLayer?.key;

        const storeChanges: MultiModel = [];

        const currentFormat = FormatHelpers.currentFormat(formats, selectedFormats);

        changes.forEach(({ model, value }) => {
            // Update general.
            if (currentFormat === 'general') {
                const targetPath = selectedLayerKey
                    ? `layerProperties.general.${frameType}.${selectedLayerKey}.${generatedUpdateKey}.${model}`
                    : `layerProperties.general.${frameType}.${generatedUpdateKey}.${model}`;

                storeChanges.push([targetPath, value]);
            }
            // Update format.
            else if (currentFormat !== 'general') {
                selectedFormats.forEach((format) => {
                    const targetPath = selectedLayerKey
                        ? `layerProperties.${format}.${frameType}.${selectedLayerKey}.${generatedUpdateKey}.${model}`
                        : `layerProperties.${format}.${frameType}.${generatedUpdateKey}.${model}`;

                    storeChanges.push([targetPath, value]);
                });
            }
        });

        TemplateDesignerStore.save(storeChanges, storeOptions);
    };

    /**
     * Resets the overwrite for the given property key.
     * @param propertyKey - The property key to reset the overwrite for.
     * @returns general style value for the given property key.
     */
    const resetOverwrites = (propertyKeys: string[], layer?: Layer['key']): void => {
        let model = `${selectedFormat}.${frameType}.${generatedUpdateKey}`;
        if (selectedLayerKey) model = `${selectedFormat}.${frameType}.${layer || selectedLayerKey}.${generatedUpdateKey}`;

        const changes: MultiModel = [];

        const layerPropertiesOverwrites = getTemplateData<Template['layerProperties']>(`layerProperties.${model}`);

        /**
         * Unset the overwrites for the given property keys.
         * If the overwrite is empty, remove it.
         */
        propertyKeys.forEach((propertyKey) => {
            unset(layerPropertiesOverwrites, propertyKey);
            deleteObjectWhenEmpty(layerPropertiesOverwrites);
        });

        changes.push([`layerProperties.${model}`, layerPropertiesOverwrites]);

        TemplateDesignerStore.save(changes);
    };

    /**
     * Applies the given property keys to all formats.
     * @param propertyKeys - The property keys to apply to all formats.
     * @param layer - The layer to apply the property keys to. If undefined, the selected layer will be used.
     */
    const applyOverwriteToAll = (propertyKeys: string[], layer?: Layer['key']) => {
        const changes: MultiModel = [];

        let generalModel = `general.${frameType}.${generatedUpdateKey}`;
        if (selectedLayerKey) generalModel = `general.${frameType}.${layer || selectedLayerKey}.${generatedUpdateKey}`;

        let formatModel = `${selectedFormat}.${frameType}.${generatedUpdateKey}`;
        if (layer || selectedLayerKey) formatModel = `${selectedFormat}.${frameType}.${layer || selectedLayerKey}.${generatedUpdateKey}`;

        // Apply the given properties to general.
        propertyKeys.forEach((propertyKey) => {
            const value = getTemplateData(`layerProperties.${formatModel}.${propertyKey}`);
            if (!value) return;
            changes.push([`layerProperties.${generalModel}.${propertyKey}`, value]);
        });

        // Remove overwrites for the given property keys on every format.
        formats.forEach((format) => {
            let model = `${format.key}.${frameType}`;
            if (layer || selectedLayerKey) model = `${format.key}.${frameType}.${layer || selectedLayerKey}`;

            const layerPropertiesOverwrites = getTemplateData<Template['layerProperties']>(`layerProperties.${model}`);

            if (!layerPropertiesOverwrites || !get(layerPropertiesOverwrites, generatedUpdateKey)) return;

            propertyKeys.forEach((propertyKey) => {
                unset(layerPropertiesOverwrites, `${generatedUpdateKey}.${propertyKey}`);
                deleteObjectWhenEmpty(layerPropertiesOverwrites);
            });

            changes.push([`layerProperties.${model}`, layerPropertiesOverwrites]);
        });

        TemplateDesignerStore.save(changes);
    };

    /**
     * Applies the given property keys to general.
     * @param propertyKeys - The property keys to apply to general.
     * @param layer - The layer to apply the property keys to. If undefined, the selected layer will be used.
     */
    const applyOverwriteToGeneral = (propertyKeys: string[], layer?: Layer['key']) => {
        let generalModel = `layerProperties.general.${frameType}.${generatedUpdateKey}`;
        if (selectedLayerKey) generalModel = `layerProperties.general.${frameType}.${layer || selectedLayerKey}.${generatedUpdateKey}`;
        let overwriteModel = `layerProperties.${selectedFormat}.${frameType}.${generatedUpdateKey}`;
        if (selectedLayerKey) overwriteModel = `layerProperties.${selectedFormat}.${frameType}.${layer || selectedLayerKey}.${generatedUpdateKey}`;

        const changes: MultiModel = [];

        const layerPropertiesGeneral = getTemplateData<Template['layerProperties']>(`${generalModel}`);
        const layerPropertiesOverwrites = getTemplateData<Template['layerProperties']>(`${overwriteModel}`);

        /**
         * Get the overwrite for the selected format.
         * Apply the overwrite to the general properties.
         */
        propertyKeys.forEach((propertyKey) => {
            const overwrite = get(layerPropertiesOverwrites, propertyKey);

            if (overwrite === undefined) {
                const value = get(layerPropertiesGeneral, propertyKey);
                changes.push([`${generalModel}.${propertyKey}`, value]);
            } else {
                changes.push([`${generalModel}.${propertyKey}`, overwrite]);
            }
        });

        TemplateDesignerStore.save(changes);
    };

    /**
     * Resets the highlighted styling for the given property keys.
     * @param propertyKeys - The property keys to reset the highlighted styling for.
     * @param layer - The layer to reset the highlighted styling for. If undefined, the selected layer will be used.
     */
    const resetHighlightedStyling = (propertyKeys: string[], layer?: Layer['key']): void => {
        let model = `${selectedFormat}.${frameType}.${generatedUpdateKey}`;
        if (selectedLayerKey) model = `${selectedFormat}.${frameType}.${layer || selectedLayerKey}.${generatedUpdateKey}`;

        const changes: MultiModel = [];

        const layerProperties = getTemplateData<Template['layerProperties']>(`layerProperties.${model}`);

        propertyKeys.forEach((propertyKey) => {
            unset(layerProperties, propertyKey);
        });

        changes.push([`layerProperties.${model}`, layerProperties]);

        TemplateDesignerStore.save(changes);
    };

    /**
     * The value of the layer properties.
     * If only one format is selected, the format properties will be used.
     * If all formats are selected, the general properties will be used.
     */
    const value: Data = useMemo(() => {
        let newValue = cloneDeep(generalStyle);

        /**
         * If only one format is selected and the selected formats is not the same as the formats, merge the general and format properties.
         */
        if (selectedFormat !== 'general') {
            newValue = merge(cloneDeep(generalStyle), cloneDeep(formatStyle));
        }

        // If the update key is animations, then don't set the default value.
        if (updateKey === 'animations') {
            return newValue;
        }

        /**
         * If the value is undefined, set it to the default value.
         */
        propertyKeys.forEach((key) => {
            const value = get(newValue, key);

            if (updateKey === 'hover' && value === undefined) {
                const defaultValue = (() => {
                    if (!selectedLayerKey) {
                        return get(defaultFormatProperties, `${updateKey}.${key}`);
                    }

                    return get(defaultHoverProperties, key);
                })();

                set(newValue, key, cloneDeep(defaultValue));
            } else if (value === undefined && selectedLayer) {
                const defaultProperties = LayerPropertiesHelpers.getDefaultProperties(selectedLayer.type);
                const defaultValue = get(defaultProperties, key);

                if (defaultValue !== undefined) {
                    set(newValue, key, cloneDeep(defaultValue));
                }
            } else if (value === undefined && !selectedLayerKey) {
                set(newValue, key, get(defaultFormatProperties, key));
            }
        });

        return newValue;
    }, [generalStyle, formatStyle, editorStyle, selectedFormats, selectedLayerKey]);

    /**
     * The overwrites for the layer properties.
     */
    const overwrites: LayerEditOverwrites = useMemo(() => {
        if (selectedFormat === 'general' || selectedFormats.length !== 1 || updateKey === 'animations') return {};

        return propertyKeys.reduce(
            (all, key) => {
                const formatOverwriteValue = get(formatStyle, key);
                if (formatOverwriteValue === undefined) return all;
                const keys = key.split('.');
                const lastKey = keys[keys.length - 1];
                if (!lastKey) return all;
                all[lastKey] = true;
                return all;
            },
            {} as { [key: string]: boolean }
        );
    }, [propertyKeys, selectedFormats, formatStyle]);

    // Also when there is an overwrite, but that gets checked per input component.
    const showResetToGeneral = selectedFormat !== 'general' && selectedFormats.length === 1;
    const showApplyToGeneral = selectedFormat !== 'general' && selectedFormats.length === 1;
    const showApplyToEveryFormat = formats.length > 1;

    return {
        value: value as Data,
        generalStyle: generalStyle as Data,
        formatStyle: formatStyle as Data,
        onChange,
        selectedLayer,
        selectedFormats,
        overwrites,
        resetOverwrites,
        applyOverwriteToAll,
        applyOverwriteToGeneral,
        resetHighlightedStyling,
        showResetToGeneral,
        showApplyToGeneral,
        showApplyToEveryFormat
    };
};

export { useLayerEdit };
